import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../MainLayout';
import 'basiclightbox/dist/basicLightbox.min.css';
import { IAlternativesQuery } from '../../queries/alternatives/types';
import { ICaseFrontmatter, ICaseQueryEdge } from '../../queries/cases/types';
import { CasePageContent } from '../../components/case/CasePageContent';
import { TempoCasePageContent } from '../../components/case/TempoCasePage';
import { ArchiveCase } from '../../components/case/ArchiveCase';

export type IData = {
  page: {
    frontmatter: ICaseFrontmatter;
    html: string;
  };
  projects: {
    edges: ICaseQueryEdge[];
  };
} & IAlternativesQuery;

type IProps = PageProps<IData, { slug: string }>;

const pages: Record<string, FC<any>> = {
  active: CasePageContent,
  archived: ArchiveCase,
  inDevelop: TempoCasePageContent,
};

export default ({ data, pageContext: { slug } }: IProps) => {
  const page = data?.page;
  const projects = data.projects.edges.filter((edge) => edge.node);
  const PageContent = pages[page.frontmatter.status];

  return (
    <MainLayout locale="ru" title={page.frontmatter.title}>
      {PageContent && (
        <PageContent slug={slug} page={page} projects={projects} />
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    page: markdownRemark(fileAbsolutePath: { eq: $slug }) {
      html
      fileAbsolutePath
      frontmatter {
        title
        status
        description
        color
        link
        preview {
          backgroundImage
          subtitle
          description
          deviceImage
          termsBackground
          terms {
            image
            title
            text
          }
          webMobile
        }
        taskAndSolution {
          solutionParagraphs
          solutionImg
          taskParagraphs
          taskImg
        }
        process {
          backgroundColor
          descriptionParagraphs
          mostDifficultParagraphs
          solutionsParagraphs
          conclusionsParagraphs
          adviceText
          linesColor
          projectManagerWords {
            img
            name
            position
            text
          }
        }
        cards
        stack {
          color
          infoText
          mobileList {
            iconName
            text
          }
          backendList {
            iconName
            text
          }
        }
        feedback {
          name
          position
          text
        }
        result {
          rotate
          platforms
          linkStore {
            iOS
            Android
          }
          screens
          resultParagraphs
          colors {
            primary
            secondary
          }
        }
        presentation
        offer {
          backgroundColor
          background
          ideaImg
          buttonColor
        }
        similarCases
      }
    }

    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/cases/projects/" } }
      sort: { fields: fileAbsolutePath, order: ASC }
    ) {
      ...CaseFields
    }
  }
`;
